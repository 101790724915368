import { Grid, Typography, Container, Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import Seo from "../../assets/components/seo";
import Navbar from "../../assets/components/Navbar";
import { useTranslation } from "react-i18next";
import { graphql, navigate } from "gatsby";
import Footer from "../../assets/components/Footer";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import Convocatoria from "../../../static/Convocatoria.pdf";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

const Index = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentConvocatoria, setCurrentConvocatoria] = useState(
    "https://howfix.net/wp-content/uploads/2018/02/sIaRmaFSMfrw8QJIBAa8mA-article.png"
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo />
      <Navbar />

      <Grid container justifyContent="center" px={2} pb={8} spacing={8}>
        <Grid item container maxWidth={1400} marginTop={12}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                color="white"
                position="absolute"
                textAlign="center"
              >
                {t("principal")}
              </Typography>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/07/03/20230703153036-31df74fb-me.jpg"
                alt="festival del chocolate tabasco"
                style={{
                  width: "100%",
                  height: 228,
                  objectFit: "cover",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item container maxWidth={1400}>
          <Grid item container spacing={2}>
            {t("concursos", { returnObjects: true })
              .filter((item) => item.image != "")
              .map((concurso, i) => (
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  justifyContent="center"
                  spacing={1}
                  marginBottom={4}
                  key={i}
                >
                  <Grid item xs={12}>
                    <img
                      src={
                        require(`../../assets/images/concursos/${concurso.image}.jpg`)
                          .default
                      }
                      alt={concurso.name}
                    />
                  </Grid>
                  {concurso.convocatoria ? (
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setCurrentConvocatoria(concurso.convocatoria);
                          setOpen(true);
                        }}
                      >
                        Ver convocatoria
                      </Button>
                    </Grid>
                  ) : null}

                  {concurso.url ? (
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => navigate(concurso.url)}
                      >
                        Registrarse
                      </Button>
                    </Grid>
                  ) : null}

                  {concurso.isClosed && (
                    <Typography>Proceso de registro concluido</Typography>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Footer />

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="lg" // Limita el tamaño del Dialog (puedes ajustar a 'md', 'sm', etc.)
          fullWidth // Hace que el Dialog ocupe el 100% del ancho posible dentro de la limitación
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              padding: 0, // Eliminar cualquier padding innecesario en el Paper
            },
          }}
        >
          <div
            style={{
              height: "85vh", // Ocupa el 85% de la altura de la pantalla (ajustable)
              width: "100%", // Ocupa el 100% del ancho disponible en el Dialog
            }}
          >
            <Viewer
              defaultScale={SpecialZoomLevel.PageFit} // Ajusta el PDF al tamaño del contenedor
              fileUrl={currentConvocatoria} // Usa la URL correcta del PDF
              renderMode="svg" // Puedes probar 'canvas' si 'svg' no es el adecuado para tu caso
            />
          </div>
        </Dialog>
      </Worker>
    </Container>
  );
};

export default Index;

export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["concursos"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
